* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

#MuiButton-label{
  justify-content : 'left !important'
}

#backapp {
  background: rgb(117,108,205);
  background: linear-gradient(90deg, rgba(117,108,205,1) 0%, rgba(54,61,135,1) 100%);
}

.colorText {
  color: '#2558ab'
}

.customDatePicker {
  width: 100%;
  height: 40px;
}

button:focus {
  outline: none !important;
}